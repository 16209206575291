* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Barlow", sans-serif;
  /* -webkit-font-smoothing: antialiased; */
  /* -moz-osx-font-smoothing: grayscale; */
  /* color: black; */
}
a {
  text-decoration: none;
  /* color: black; */
}

a:hover {
  text-decoration: none;
}

p {
  margin-block-start: 0em;
  margin-block-end: 0em;
}

h1 {
  margin: 0rem;
}